<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Reviews for {{ startup }}
            </h3>
          </b-card-header>

          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    class="mr-50"
                    :to="{name: 'review-questions',
                          params: {pid:$route.params.pid,
                                   apid: props.row.id,
                                   aid: $route.params.aid,},
                          query: {readonly: 'true'}}"
                  > View Review </b-button>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    :to="{name: 'review-evaluations',params: {pid:$route.params.pid,
                                                              apid: props.row.id,
                                                              aid: $route.params.aid}}"
                  >View Evaluations</b-button>
                </span>
              </span>
              <!-- Column: Action -->
              <span
                v-else-if="props.column.field === 'title'"
                class="d-flex justify-content-between"
              >
                <span>{{ props.formattedRow[props.column.field] }}
                  <b-badge
                    variant="light-primary"
                    class="mx-50"
                  >{{ props.row.type }}</b-badge></span>
                <feather-icon
                  fill="currentColor"
                  :class="props.row.programs_assignmentquestionstables[0].programs_startupresponsetables_aggregate.aggregate.count?'text-success':'text-danger'"
                  icon="CircleIcon"
                />
              </span>
              <span
                v-else-if="props.column.label === 'Verdict'"
                class="d-flex justify-content-between align-items-center"
              >
                {{ props.formattedRow[props.column.field] || '-' }}
                <b-button
                  size="sm"
                  variant="flat-primary"
                  @click="selectedRow=props.row.originalIndex; $bvModal.show('recommendationModal')"
                >Update</b-button>
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] || '-' }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>

    <b-modal
      id="recommendationModal"
      ok-only
      ok-title="Update"
      title="Recommendations"
      @ok="updateStatus"
    >
      <div v-if="rows[selectedRow]">
        <b-form-group
          label="Recommendations"
        >
          <b-form-input
            v-model="rows[selectedRow].programs_assignmentscorerecords[0].recommendation"
            placeholder="Your recommendation for startup"
          />
        </b-form-group>
        <b-form-group
          label="Verdict"
        >
          <v-select
            v-model="rows[selectedRow].programs_assignmentscorerecords[0].verdict"
            :options="['Excellent','Good','Satisfactory','Unsatisfactory','Incomplete']"
            placeholder="Select from list"
          />
        </b-form-group>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    vSelect,
  },
  data() {
    return {
      pageLength: 5,
      selectedRow: null,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Recommendation',
          field({ programs_assignmentscorerecords }) {
            if (!programs_assignmentscorerecords.length) {
              return '-'
            }
            return programs_assignmentscorerecords[0].recommendation
          },
        },
        {
          label: 'Verdict',
          field({ programs_assignmentscorerecords }) {
            if (!programs_assignmentscorerecords.length) {
              return '-'
            }
            return programs_assignmentscorerecords[0].verdict
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      mutationLoading: false,
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    updateStatus() {
      this.mutationLoading = true
      const mutationObject = {
        ...this.rows[this.selectedRow].programs_assignmentscorerecords[0],
      }
      // eslint-disable-next-line no-underscore-dangle
      this.$apollo.mutate(
        {
          mutation: gql`mutation MyMutation($object: programs_assignmentscorerecord_insert_input!) {
              insert_programs_assignmentscorerecord_one(object: $object, on_conflict: {constraint: programs_assignmentscorerecord_pkey, update_columns: [verdict, recommendation]}) {
                id
              }
            }`,
          variables: {
            object: mutationObject,
          },
          update: () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mutationLoading = false
            this.$apollo.queries.rows.refetch()
          },
        },
      )
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'There was an error',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
  },
  apollo: {
    startup: {
      query() {
        return gql`
        {
            programs_startupparticipants_by_pk(id:${this.$route.params.aid}) {
                programs_applicantstable{
                    users_organizationtable{
                    title
                    }
                }
            }
        }`
      },
      update: data => data.programs_startupparticipants_by_pk.programs_applicantstable.users_organizationtable.title,
    },
    rows: {
      query() {
        return gql`
        {
          programs_assignmenttable(order_by: {id: desc}, where: {status: {_neq: "draft"}, programs_operationstable: {program_id: {_eq: ${this.$route.params.pid}}}}) {
            id
            title
            type
            programs_assignmentquestionstables{
                programs_startupresponsetables_aggregate(where: {participant_id: {_eq: ${this.$route.params.aid}}}) {
                    aggregate {
                        count
                    }
                }
            }
            programs_assignmentscorerecords (where: {participant_id: {_eq: ${this.$route.params.aid}}}) {
              id
              verdict
              recommendation
              assignment_id
              }
            }
          }`
      },
      update(data) {
        const rows = data.programs_assignmenttable
        rows.forEach(row => {
          if (!row.programs_assignmentscorerecords.length) {
            row.programs_assignmentscorerecords.push({
              assignment_id: row.id,
              participant_id: this.$route.params.aid,
              verdict: null,
              recommendation: null,
            })
          }
        })
        return rows
      },
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
